<template>
  <section>
    <supplier-number-generator
      ref="supplier-number-generator"
      v-model="localForm"
    />
  </section>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import SupplierNumberGenerator from './components/supplier-number-generator'

export default {
  components: {
    SupplierNumberGenerator
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.suppliers.title')
    }
  },
  data() {
    return {
      localForm: {
        autoGenerate: false,
        template: ''
      }
    }
  },
  mounted() {
    this.fetchSupplierConfigurations()
  },
  methods: {
    async validate(cb) {
      const supplierNumberGeneratorValid = await this.$refs[
        'supplier-number-generator'
      ].validate()

      cb(supplierNumberGeneratorValid)
    },
    async fetchSupplierConfigurations() {
      //fetch supplier configuration
      const { data } = await th.inventoryConfiguration().getAll()
      this.handleData(data?.[0])
    },
    async handleSave() {
      //save supplier configuration
      const { template, autoGenerate, id } = this.localForm
      const { data } = await th
        .inventoryConfiguration()
        .put(id, autoGenerate ? { template, autoGenerate } : { autoGenerate })

      this.handleData(data)
    },
    handleData(config) {
      if (config) {
        this.localForm = {
          id: config.id,
          autoGenerate: config.autoGenerate,
          template: config.template || ''
        }
      }
    }
  }
}
</script>
