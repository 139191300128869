<template>
  <th-wrapper
    :title="$t('pages.settings.suppliers.supplier_number_generator.title')"
    :info="
      $t('pages.settings.suppliers.supplier_number_generator.instructions')
    "
  >
    <!-- autoGenerate enable -->
    <div class="mb-4">
      <el-switch
        v-model="form.autoGenerate"
        :active-text="autoGenerateSwitchLabel"
      >
        <el-input v-model="form.autoGenerate" />
      </el-switch>
    </div>

    <!-- Auto id generator -->
    <auto-id-generator
      ref="auto-id-generator"
      v-model="form.template"
      :disabled="!form.autoGenerate"
      :config-path="templatePath"
      :wanted-elements="elements"
      :show-max-input="true"
      :skip-max-input-if-empty="true"
      max-input-label="max"
    />
  </th-wrapper>
</template>

<script>
import AutoIdGenerator from '@/views/settings/sections/dashboard/components/auto-id-generator'

export default {
  name: 'SupplierNumberGenerator',
  components: {
    AutoIdGenerator
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      templatePath: 'suppliers.supplier_number_template',
      switchPath: 'suppliers.generate_supplier_number',
      elements: [
        'full_date',
        'year',
        'month',
        'day',
        'text',
        'sequence',
        'dash'
      ]
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    autoGenerateSwitchLabel() {
      return this.form.autoGenerate
        ? this.$t(
            'pages.settings.suppliers.supplier_number_generator.switch.enabled'
          )
        : this.$t(
            'pages.settings.suppliers.supplier_number_generator.switch.disabled'
          )
    }
  },
  methods: {
    validate() {
      return this.$refs['auto-id-generator'].validate()
    }
  }
}
</script>
